.body{
  display: flex;
  margin: 0;
  flex-direction: column;
  text-align: center;
  color: #fefefe;
  font-family: 'Lora', serif;
}
.footer{
  height: 6em;
  background-image: linear-gradient( to right, #32a3e9,#1e46ae,#0674b9);
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* position:absolute; */
  bottom:0;
  width:100%;
    
}
.isotipoFooter{
  height: 5em;
}
.separadorFooter{
    width: 2px;
    height: 4em;
    background-color: white;
}
.circuloIconosFooter{
  display: flex;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  background-color: black;
  justify-content: center;
    align-items: center;
}
.textoFooter{
  font-size: 15px;
}