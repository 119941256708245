.tamañoLogos{
    width: 5em;
    height: 3em;
    border-radius: 10%;
}
.containerPadreLogos{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10em;
    box-shadow: 2px -1px 35px 3px rgba(0,0,0,0.75);
    font-family: 'Lora', serif;
}
.containerLogos{
    display: flex;
    width: 63em;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em; 
}
h1{
    font-size: 2em;
}
label{
    font-size: 22px;
}