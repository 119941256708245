.body{
    display: flex;
    margin: 0;
    flex-direction: column;
    text-align: center;
    color: #fefefe;
  }
  .header{
    background-image: linear-gradient( to right, #32a3e9,#1e46ae,#0674b9);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 6em;
  }
  .estiloLetraHeader{
     color: white; 
  }
  .separador{
      width: 2px;
      height: 2em;
      background-color: white;
  }
  .LogoPrincipal{
      width: 10em;
  }
  .colorIcono{
    color: white;
   
  }
  
  
  /* HEADER2 */
  .header2{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 2em;
      background-color: white;
      box-shadow: 0px 0px 52px 3px rgba(0,0,0,0.75);
  }
  .aHeader2{
      text-decoration: none;
      color: black;
  }