body {
  margin: 0;
 /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
/*   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}

.fondo {
    background-image: url(/static/media/codigo.1ec38e12.jpg);
    border-bottom-right-radius: 95%;
    height: 50em;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 66px;
    opacity: 0.7;
  }
  .div-login {
    margin-top: 10em;
    width: 33em;
    margin-left: 53em;
    border-radius: 2em;
    height: 28em;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: black !important;

  }
.btn-fondo{
  background-color: #0674b9;
  color: black;
}
a{
  text-decoration: none;
  color: black;
}
 
.logoIsotipo{
  height: 28px;
    margin-top: 4px;
    margin-left: 19px;
}

.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}

.icon-barra {
  color: white;
  margin-left: 22px;
    width: 37px;
}


.main-menu:hover,
nav.main-menu.expanded {
  width: 250px;
  overflow: visible;
}

.main-menu {
  background-image: linear-gradient(#32a3e9, #1e46ae, #0674b9);
  border-right: 1px solid #e5e5e5;
  position: absolute;
  top: 0;
  bottom: 0;
  height: 60em;
  left: 0;
  width: 60px;
  overflow: hidden;
  transition: width 0.05s linear;
  -webkit-transform: translateZ(0) scale(1, 1);
  z-index: 1000;
}

.main-menu > ul {
  margin: 7px 0;
}

.main-menu li {
  position: relative;
  display: block;
  width: 250px;
}

.main-menu li > a {
  position: relative;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  color: #999;
  font-family: arial;
  font-size: 14px;
  text-decoration: none;
  -webkit-transform: translateZ(0) scale(1, 1);
  transition: all 0.1s linear;
}

.main-menu .nav-icon {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 18px;
}

.main-menu .nav-text {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  width: 190px;
  font-family: "Titillium Web", sans-serif;
  color: white;
  font-size: 1em;
}

.main-menu > ul.logout {
  position: absolute;
  left: 0;
  bottom: 0;
}

.no-touch .scrollable.hover {
  overflow-y: hidden;
}

.no-touch .scrollable.hover:hover {
  overflow-y: auto;
  overflow: visible;
}

a:hover,
a:focus {
  text-decoration: none;
}

nav {
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

nav ul,
nav li {
  outline: 0;
  margin: 0;
  padding: 0;
}

.main-menu li:hover > a,
nav.main-menu li.active > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus,
.no-touch .dashboard-page nav.dashboard-menu ul li:hover a,
.dashboard-page nav.dashboard-menu ul li.active a {
  color: #fff;
  background-color: #5fa2db;
}

.area {
  float: left;
  background: #e2e2e2;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Titillium Web";
  font-style: normal;
  font-weight: 300;
  src: local("Titillium WebLight"), local("TitilliumWeb-Light"),
    url(http://themes.googleusercontent.com/static/fonts/titilliumweb/v2/anMUvcNT0H1YN4FII8wpr24bNCNEoFTpS2BTjF6FB5E.woff)
      format("woff");
}
.nav-background{
  background-image: linear-gradient( to right, #32a3e9,#1e46ae,#0674b9);
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  font-family: 'Prata', serif;
  font-size: 16px;
}
.fa {
  position: relative;
  display: table-cell;
  width: 60px;
  height: 21px;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
}
.container{
  height: 31em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  font-family: 'Marcellus', serif;
}
.containerTitulo{
  display: flex;
  justify-content: space-between;
}
.containerInfNombId{
display: flex;
}
.containerInfPrecCategCanti{
display: flex;
}
.modal-body2{
display: flex;
flex-direction: column;
height: 24em;
justify-content: space-evenly;
}
.tamañoButton{
width: 10em;
}
.imgListaProducto{
  width: 88px;
  height: 69px;
}

table{
  border: 0.1em rgb(180, 177, 177) solid;
  box-shadow: 0px -1px 10px 2px #404041;
  text-align: center;
  margin-top: 3em;
}
.tituloTabla{
  color: rgb(70, 70, 70);
}
.distIconos{
  display: flex;
    justify-content: space-evenly;
    height: 6em;
    align-items: center;
}

main {
    width: 100%;
    margin-left: 61px;
  }
.container{
  margin-top: 1em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  position: relative;
  left: 0em;
  top: 2em;
  }
.table{
  margin-top: 2em;
  }

main {
    width: 1473px;
    margin-left: 25px;
    margin-top: 1.5em;
}


.body{
    display: flex;
    margin: 0;
    flex-direction: column;
    text-align: center;
    color: #fefefe;
  }
  .header{
    background-image: linear-gradient( to right, #32a3e9,#1e46ae,#0674b9);
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 6em;
  }
  .estiloLetraHeader{
     color: white; 
  }
  .separador{
      width: 2px;
      height: 2em;
      background-color: white;
  }
  .LogoPrincipal{
      width: 10em;
  }
  .colorIcono{
    color: white;
   
  }
  
  
  /* HEADER2 */
  .header2{
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      height: 2em;
      background-color: white;
      box-shadow: 0px 0px 52px 3px rgba(0,0,0,0.75);
  }
  .aHeader2{
      text-decoration: none;
      color: black;
  }
.body{
  display: flex;
  margin: 0;
  flex-direction: column;
  text-align: center;
  color: #fefefe;
  font-family: 'Lora', serif;
}
.footer{
  height: 6em;
  background-image: linear-gradient( to right, #32a3e9,#1e46ae,#0674b9);
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* position:absolute; */
  bottom:0;
  width:100%;
    
}
.isotipoFooter{
  height: 5em;
}
.separadorFooter{
    width: 2px;
    height: 4em;
    background-color: white;
}
.circuloIconosFooter{
  display: flex;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  background-color: black;
  justify-content: center;
    align-items: center;
}
.textoFooter{
  font-size: 15px;
}
/* MAIN */
.contenedorPadre{
    display: flex;
    justify-content: center;
  }
  
  /* <!-- MENU DESPLEGABLE --> */
  
  .containerDropdown{
    display: flex;
      flex-direction: column;
      height: 13em;
      align-items: baseline;
      justify-content: space-evenly;
      margin-left: 5px;
  }
  
  /* <!-- MENU DESPLEGABLE --> */
  
  .letraMainContainerLateral{
    color: black;
    margin-top: 13px;
    margin-left: 15px;
  }
  .mainContainerLateral{
    background-color: #F2F2F2;
    width: 20em;
    height: 36em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 7px;
    margin-left: 1em;
  }
  .mainContainerDer{
    margin-left: 4em;
     width: 20em;

  }
  .d-flex{
    display: flex!important;
    flex-wrap: wrap;
    align-content: center;
  }
  .mainContainerArriba{
    height: 25em; 
    display: flex;
    justify-content: space-evenly;
  }
  .mainContainerAbajo{
    height: 19em; 
    display: flex;
    justify-content: space-evenly;
  }
  .cards{
     display: flex;
     align-items: center;
     width: 22em;
     height: 18em;
     flex-direction: column;
     justify-content: space-around;
     margin-top: 15px;
  }
  
  .cardDescripcion{
    text-align: center;
  }
  .imgCards{
    width: 17em;
    height: 12em;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .letraCardDescripcion{
    color: black;
  }
  .separadorLateral{
    width: 15em;
    height: 2px;
    background-color: black;
  }

.mainContainerPadre{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 72em;
    height: 31em;
    margin-left: 19em;
}
.containerIzq{
    height: 31em;
    width: 35em;
    display: flex;
    justify-content: center;
    align-items: center;

}
.containerIzqImgs{
    width: 5em;
    height: 201px;
}
.containerIzqImgs3{
    height: 67px;
    background-size: 98%;
    width: 5em;
}
.containerIzqImgGrande{
    width: 27em;
    height: 23em;
    background-size: 100%;
    background-repeat: no-repeat;
}
.containerDer{
    height: 23em;
    width: 35em;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    color: rgba(0, 0, 0, 0.795);
}
.containerDer1{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-around;
}
.containerDer2{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
}
.containerDer3{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    justify-content: space-evenly;
}
.containerDerTodosLogos{
    display: flex;
    width: 21em;
    height: 3em;
    justify-content: space-between;
}
.containerDerLogos{
    width: 4em;
    height: 102%;
    background-size: 107%;
    background-repeat: no-repeat;
}
.containerTallas{
    border: 6 px solid red;
    margin-left: 5px;
    text-decoration: none;
    color: black;
}
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 594px;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 0.3rem;
    outline: 0;
    top: 2em;
}
.estiloBoton{
    margin-left: 44em;
    margin-bottom: 1em;
}
.tamañoLogos{
    width: 5em;
    height: 3em;
    border-radius: 10%;
}
.containerPadreLogos{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 10em;
    box-shadow: 2px -1px 35px 3px rgba(0,0,0,0.75);
    font-family: 'Lora', serif;
}
.containerLogos{
    display: flex;
    width: 63em;
    justify-content: space-between;
    align-items: center;
    margin-top: 1em; 
}
h1{
    font-size: 2em;
}
label{
    font-size: 22px;
}

#pills-home{
    margin-left: 18em;
    margin-right: 18em;
    margin-bottom: 1em;
    border: 0.1em #0674b9 solid;
    border-radius: 1em;
    padding: 1em;

}
.card-header{
    background-image: linear-gradient( to right, #32a3e9,#1e46ae,#0674b9);
}
.table{
    border: 0.1em rgb(79, 78, 78) solid;
}
#pills-profile{
    margin-left: 18em;
    margin-right: 18em;
    border: 0.1em #0674b9 solid;
    border-radius: 1em;
    padding: 1em;
    margin-bottom: 1em;
}
#subTotalPadre{
    display:flex;
    justify-content:space-between;
    padding: 0.5em;
    align-items: center;
    color: white;
    margin-bottom: 1em;
}
#subTotal{
    width: 8em;;
}
.diseñobtn{
    display: flex;
    justify-content: space-evenly;
}
.estiloimg{
    margin-left: 5em;
    margin-top: 24px;
}
.contenidoProd{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}
