/* MAIN */
.contenedorPadre{
    display: flex;
    justify-content: center;
  }
  
  /* <!-- MENU DESPLEGABLE --> */
  
  .containerDropdown{
    display: flex;
      flex-direction: column;
      height: 13em;
      align-items: baseline;
      justify-content: space-evenly;
      margin-left: 5px;
  }
  
  /* <!-- MENU DESPLEGABLE --> */
  
  .letraMainContainerLateral{
    color: black;
    margin-top: 13px;
    margin-left: 15px;
  }
  .mainContainerLateral{
    background-color: #F2F2F2;
    width: 20em;
    height: 36em;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 7px;
    margin-left: 1em;
  }
  .mainContainerDer{
    margin-left: 4em;
     width: 20em;

  }
  .d-flex{
    display: flex!important;
    flex-wrap: wrap;
    align-content: center;
  }
  .mainContainerArriba{
    height: 25em; 
    display: flex;
    justify-content: space-evenly;
  }
  .mainContainerAbajo{
    height: 19em; 
    display: flex;
    justify-content: space-evenly;
  }
  .cards{
     display: flex;
     align-items: center;
     width: 22em;
     height: 18em;
     flex-direction: column;
     justify-content: space-around;
     margin-top: 15px;
  }
  
  .cardDescripcion{
    text-align: center;
  }
  .imgCards{
    width: 17em;
    height: 12em;
    background-size: 100%;
    background-repeat: no-repeat;
  }
  .letraCardDescripcion{
    color: black;
  }
  .separadorLateral{
    width: 15em;
    height: 2px;
    background-color: black;
  }